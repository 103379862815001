import React from "react"
import {Link} from "gatsby"
import {Hero, Columns, Container, Heading, Section, Content} from 'react-bulma-components';
import Sponsors from "../components/sponsors"
import Socialmedia from "../components/socialmedia"

const Footer = () => { 
      
  return (
    <>
    <Hero>    
      <Hero.Body>
        <Container>
        <Columns>
          <Columns.Column>
          <strong>Green Heat Sp. z o.o.</strong><br/>
          Grzybowska 4/106<br/>
          00-131 Warszawa<br/>
          KRS 0000779313 / NIP 7393925996<br/>
          REGON 382979500<br/>
          </Columns.Column>  
          <Columns.Column>
          <Content>
          <strong>Contact us</strong>:
          </Content>
          <Socialmedia/>
          <Content>
            <Link to="/privacypolicy" className="item">Privacy policy</Link> | <Link to="/blog">Blog</Link>
          </Content>
          </Columns.Column>
        </Columns>
        </Container>
      </Hero.Body>
      </Hero>
      
      <Section>
        <Container>
          <Heading renderAs="h2" textAlign="center">
          Supported by
          </Heading>
          <Sponsors/>
        </Container>
      </Section>
      </>
  )
}

export default Footer
