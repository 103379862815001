import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {Columns} from "react-bulma-components"

const Sponsors = () => {
  const data = useStaticQuery(graphql`
    query {
      platformy: file(relativePath: { eq: "platformy.png" }) {
        childImageSharp {
          fixed(width: 190, height: 58) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      eufunds: file(relativePath: { eq: "sponsors/logo_FE_Eastern_Poland_rgb-1.jpg" }) {
        childImageSharp {
          fixed(width: 190) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      startupheroes: file(relativePath: { eq: "sponsors/startupheroes.png" }) {
        childImageSharp {
          fixed(width: 153, height: 73) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      polska: file(relativePath: { eq: "sponsors/polska.png" }) {
        childImageSharp {
          fixed(width: 201, height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
    <Columns className="is-vcentered" textAlign="center">
    <Columns.Column><Img alt="Platformy startowe" fixed={data.platformy.childImageSharp.fixed} /></Columns.Column>
    <Columns.Column><Img alt="Polish flag" fixed={data.polska.childImageSharp.fixed} /></Columns.Column>
    <Columns.Column><Img alt="Eurepean Funds Eastern Poland" fixed={data.eufunds.childImageSharp.fixed} /></Columns.Column>
    <Columns.Column><Img alt="Startup Heroes logo" fixed={data.startupheroes.childImageSharp.fixed} /></Columns.Column>
    </Columns>
    </>
  )
}

export default Sponsors
