import React from "react"

//import { Link } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import LogoImage from "../components/logoimage"
import Seo from "../components/seo"
import ProductShowcase from "../components/productshowcase"
import CertsMedia from "../components/certsmedia"
import {Hero, Columns, Container, Heading, Navbar, Section, Content, Button} from 'react-bulma-components'
import Footer from "../components/footer"
import { OutboundLink } from 'gatsby-plugin-gtag'
import Signup from '../components/signup'
import CookieConsent from "react-cookie-consent";

const IndexPage = () => {
  
  return (  
    <Layout>
      <Seo title="Heat recovery GPU mining rigs" />
      <CookieConsent
        style={{ background: "#000" }}
        buttonStyle={{ color: "#000", background: "#fff", fontSize: "13px" }}
      >
          This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Hero size="fullheight" color="primary">
      <Hero.Header renderAs="header">
        <Container>
        <Navbar>
          <Navbar.Brand>
            <Navbar.Item href='/'>
              <LogoImage type="white"/>
            </Navbar.Item>
            
          </Navbar.Brand>
        </Navbar>
        </Container>
      </Hero.Header>
      <Hero.Body>
        <Container>
        <Columns className="is-vcentered">
        <Columns.Column size="two-thirds">
            <StaticImage src="../images/products/heat-recovery-tank-closed.png" alt="Home heating, media center, cryptocurrency mining device "/>
          </Columns.Column>  
          <Columns.Column>
            <Heading>Heat Recovery For GPU mining rigs</Heading>
              <Content>
              Use bitcoin or GPU based miners to <strong>heat your home</strong> or a swimming pool, reduce electric heating bills, 
              make your <strong>mining rig</strong> silent, good looking and protected from dust and overheating
              </Content>
              <Content>
                Signup now and receive <strong>10% discount</strong> on our crypto mining heater. 
              </Content>
              <Signup/>
              
          </Columns.Column>
          
        </Columns>
        </Container>
      </Hero.Body>
      </Hero>
      
      

      <ProductShowcase/>

      <Hero color="primary">    
      <Hero.Body>
        <Container>
        <Heading textAlign="center" renderAs="h2">Home heating, powerful computing platform, reduced emissions</Heading>
        <Columns textAlign="center">
        <Columns.Column>
        <Section>
        <Heading subtitle renderAs="h3">
          Powerful <strong>silent</strong> and protected computing platform<br/> 
          <strong>cost effective mining</strong>, green home heating, <strong>reduced low emissions</strong> from your building.
          </Heading>
          </Section>
        </Columns.Column>
        </Columns>
        <Columns textAlign="justify">
          <Columns.Column>
            <Content>
            HeatMiner reduces low building emissions by using electricity for heating instead of burning coal or gas 
            in your home which contributes to the emergence of smog in the city. Our mission is to <strong>bring bitcoin mining back home</strong>  
            </Content>
          </Columns.Column>  
          <Columns.Column>        
            <Content>
              Let's foster <strong>decentralization of bitcoin mining</strong>.
              Achieve a synergy between the need to heat your place you live 
              and the byproduct of mining which is waste heat normally being a problem for large scale mining facilities. 
            </Content>
          </Columns.Column>  
          <Columns.Column >  
            <Content>
            If you decide to use GPU mining rig along with HeatMiner you can also beneft from having <strong>cost effective media center</strong> 
              or use the device as a powerful gaming platform for your favorite games, rendering software or movie editing. 
              
            </Content>
          </Columns.Column>
        </Columns>
        
        
        </Container>
      </Hero.Body>
      </Hero>

      <Hero>    
      <Hero.Body>
        <Container>
        <Columns className="is-vcentered" >
          <Columns.Column>
          <StaticImage src="../images/products/heat-recovery-tank-gpu-oil.png" alt="GPU miner and media center electric heater"/>
          </Columns.Column>  
          <Columns.Column>  
          <Heading renderAs="h2">
          HeatMiner benefits
          </Heading>  
          <Content>
              <ul>
                <li>Enable green <strong>heat recovery</strong> from your mining rig to heat your home</li>
                <li>Make your monster GPU device <strong>completely silent</strong></li>
                <li>Safely <strong>overclock</strong> your graphic cards</li>
                <li><strong>Protect electronic circuits</strong> from dust and heat</li>
                <li>Make your cryptocurrency mining rig look <strong>cool and discreet</strong></li>
                <li>Heat your swimming pool or running water using boiler version of HeatMiner</li>
                <li>Reduce electric heating bills using cryptocurrency</li>
                <li>Go green and reduce low level emissions of your home</li>
              </ul>
            </Content>
          </Columns.Column>
        </Columns>
        </Container>
      </Hero.Body>
      </Hero>

      <Hero color="light">
        
        <Hero.Body>
            <Section>
            <Container>
            <Columns>
              <Columns.Column size="half">            
              <Heading renderAs="h2">
              Help us build better home heating device
              </Heading>
              <Content>
              Take a survey and <a href="https://forms.gle/u2DxGPFXRv89fWAe7">answer key important questions</a> so we can suggest the best solution for heating your home.
              </Content>
              <Content>
              <OutboundLink href='https://forms.gle/u2DxGPFXRv89fWAe7'><Button>Take a survey</Button></OutboundLink>
              </Content>
              </Columns.Column>              
              <Columns.Column>
              
              </Columns.Column>
            </Columns>
          </Container>
          </Section>
        </Hero.Body>        
      </Hero>

      <CertsMedia/>
      <Footer/>
      
    </Layout>
  )
}

export default IndexPage
