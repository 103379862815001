import React,  { Suspense, lazy } from "react"

import * as EmailValidator from 'email-validator';
import {Form, Button} from 'react-bulma-components'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

const bugsnagClient = bugsnag('4a20d70b23391ed22152b7ca60e899ad')
bugsnagClient.use(bugsnagReact, React)

const ReCAPTCHA = lazy(() => import("react-google-recaptcha"));
class Signup extends React.Component {
  state = {
    email: '',
    validEmail: false,
    startSignup: false
  }
  onBegin = evt => {
    this.setState({startSignup: true})
    bugsnagClient.leaveBreadcrumb('Begin signup')
  }
  onChange = evt => {
    const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    this.setState({
      [evt.target.name]: value,
    });
    if(this.state.email.length > 3) {
      this.setState({validEmail: EmailValidator.validate(this.state.email)})
    }
  };
  
  render () {
    const {
      email, 
      startSignup
      //validEmail
    } = this.state;
    return (  
      <>
            {!startSignup ? (
              <Button type="primary" onClick={this.onBegin}>Signup and get 10% discount</Button>
            ) : (
              <form name="Signup" method="POST" data-netlify="true" action="/thanks" data-netlify-recaptcha="true">
              <Form.Field>
                <Form.Label textColor='white'>Your Email: </Form.Label>
                <Form.Control>
                  <Form.Input type="email" name="email" placeholder="enter valid email" onChange={this.onChange} value={email}/>
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <Suspense fallback="Loading...">
                  <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY} />
                </Suspense>
              </Form.Field>
              <Form.Field>
                <Form.Control>
                  <Button type="primary">Join the waiting list</Button>
                </Form.Control>
              </Form.Field>
              <input type="hidden" name="form-name" value="Signup" />
              </form>
            )}
       </>   
    )
  }
  
}

export default Signup
