import React from "react"
import {Hero, Columns, Container, Content, Heading, Navbar, Section} from 'react-bulma-components';
import LogoImage from "./logoimage";
import { StaticImage } from "gatsby-plugin-image"

const ProductShowcase = () => {
        
  return (
    <Hero scolor="light">
      <Hero.Header renderAs="header">
        <Section>
            <Container>
            <Navbar>
                <Navbar.Brand>
                <Navbar.Item>
                    <LogoImage type="green"/>
                </Navbar.Item>
                </Navbar.Brand>
            </Navbar>
            </Container>
        </Section>
      </Hero.Header>
      <Hero.Body>
          <Section>
          <Container>
          <Columns>
            <Columns.Column size="half">
            <Heading renderAs="h2">Heatminer</Heading>
            <Content>
            HeatMiner helps you <strong>recover heat from any GPU or ASIC mining equipment</strong> to increase profitability 
            by effective reuse of heat generated while mining to heat your home and reduce the need for alternative ways of home heating. 
            </Content>
            <Content>
            Thanks to HeatMiner you can also <strong>reduce noise</strong> from the level of a rock concert 
            to a level acceptable at home where you work and sleep. 
            </Content>
            <Content>
            Our device greatly <strong>extends lifetime</strong> of miners by protecting from dust, overheating and mechanical damage. 
            Effective cooling allows for <strong>safe overclocking</strong> of existing hardware.
            </Content>
            </Columns.Column>  
            <Columns.Column>
            <StaticImage src="../images/products/heat-recovery-tank-open.png" height={400} transformOptions={{trim: 0}} alt="GPU miner and media center electric heater"/>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column size="half">
            <StaticImage src="../images/products/heat-recovery-tank-put-gpu.png" alt="GPU miner and media center electric heater"/>         
            </Columns.Column>  
            <Columns.Column>
            <StaticImage src="../images/products/heat-recovery-tank-gpu-oil.png" alt="GPU miner and media center electric heater"/>
            </Columns.Column>
          </Columns>
          
        </Container>
        </Section>
      </Hero.Body>        
    </Hero>
  )
}

export default ProductShowcase
