import React from "react"
import {Hero, Columns, Container, Heading, Navbar, Section} from 'react-bulma-components';
import LogoImage from "./logoimage";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CertsMedia = () => {
  
  const data = useStaticQuery(graphql`
    query {
      jen: file(relativePath: { eq: "certs/jen.png" }) {
        childImageSharp {
          fixed(width: 73) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      instytut: file(relativePath: { eq: "certs/instytut.png" }) {
        childImageSharp {
          fixed(width: 73) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ceo: file(relativePath: { eq: "inmedia/ceo.png" }) {
        childImageSharp {
          fixed(width: 148) {
            ...GatsbyImageSharpFixed
          }
        }
      }     
      comparic: file(relativePath: { eq: "inmedia/comparic.png" }) {
        childImageSharp {
          fixed(width: 148) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      academy: file(relativePath: { eq: "inmedia/startupacademy.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      platformy: file(relativePath: { eq: "inmedia/platformy.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
    
  return (
    <Hero>  
      <Hero.Header renderAs="header">
      <Section>
              <Container>
              <Navbar>
                  <Navbar.Brand>
                  <Navbar.Item><LogoImage type="green"/></Navbar.Item>
                  </Navbar.Brand>
              </Navbar>
              </Container>
          </Section>
      </Hero.Header>  
      <Hero.Body>
        <Container>
        <Columns textAlign="center">
          <Columns.Column>
          
          </Columns.Column>  
          <Columns.Column>
          <Section>
            <Heading renderAs="h2">
            Certified by
            </Heading>
            <Columns>
            <Columns.Column>
            <a href="https://www.ien.com.pl/home"><Img alt="Institute of Power Engineering" fixed={data.jen.childImageSharp.fixed}/></a>
            </Columns.Column>
            <Columns.Column>
            <a href="https://www.ciop.pl/en"><Img alt="Central Institute for labour protection - national research institute" fixed={data.instytut.childImageSharp.fixed}/></a>
            </Columns.Column>
            </Columns>      
          </Section>  
          <Section>
            <Heading renderAs="h2">
            In News
            </Heading>
            <Columns textAlign="center">
            <Columns.Column>
            <a href="https://ceo.com.pl/fundacja-polska-przedsiebiorcza-wybrala-7-najbardziej-rokujacych-startupow-z-mazowsza-81134">
              <Img alt="CEO magazine" fixed={data.ceo.childImageSharp.fixed}/></a>
            </Columns.Column>            
            <Columns.Column>
            <a href="https://comparic.pl/ogrzewa-koparka-kopie-piecem-twierdzi-ze-tak-jest-taniej/">
              <Img alt="Comparic article about Heatminer" fixed={data.comparic.childImageSharp.fixed}/></a>
            </Columns.Column>            
            </Columns>
            <Columns>
            <Columns.Column>            
            <a href="http://przedsiebiorczemazowsze.pl/heatminer-klaster-zielonej-energii-zasilany-technologia-blockchain/">
              <Img alt="Article about HeatMiner in Przedsiębiorcze Mazowsze" fixed={data.academy.childImageSharp.fixed}/></a>
            </Columns.Column>            
            <Columns.Column>
            <a href="https://opnt.olsztyn.pl/en/"><Img alt="OPNT partner logo" fixed={data.platformy.childImageSharp.fixed}/></a>
            </Columns.Column>            
            </Columns>
          </Section>      
          
          </Columns.Column>
        </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default CertsMedia
